<template lang="pug">
.roles-shell
    .box
        .box__header
            TitlePage(
                title="Territory Virtual"
                :breadcrumb="breadcrumb"
                )
        .box__body
            router-view
</template>

<script>
import TitlePage from '@/components/shell/TitlePage.vue'
export default {
    name: 'TerritoryVirtualShell',
    components: {
        TitlePage,
    },
    data() {
        return {
            // 
        }
    },
    computed: {
        route() {
            return this.$route;
        },
        // id() {
        //     return this.$route.params.id;
        // },
        breadcrumb() {
            let breadcrumb = [
                {
                    name: 'Territory Virtual',
                    url: '/global-territory-virtual',
                },
            ];

                if (this.route.matched[0] && this.route.matched[0].components.default.name === 'TerritoryVirtualShell' && this.route.matched[1]) {
                    switch(this.route.matched[1].name) {
                        case 'territory-virtual-detail':
                            breadcrumb.push({
                                name: 'Detail Territory Virtual',
                                url: '/global-territory-virtual/'+this.id+'/detail',
                            });
                            break;
                        case 'territory-virtual-create':
                            breadcrumb.push({
                                name: 'Add Territory Virtual',
                                url: '/global-territory-virtual/create',
                            });
                            break; 
                        case 'territory-virtual-edit':
                            breadcrumb.push({
                                name: 'Edit Territory Virtual',
                                url: '/global-territory-virtual/'+this.id+'/edit',
                            });
                            break;                           
                            
                        default:
                            // code block
                    }
                }


            return breadcrumb;        
        },
    },
    watch: {
        route() {},
        id() {},
        breadcrumb() {},
    },
    created() {
        // 
    },
    mounted() {
        // 
    },
}
</script>
